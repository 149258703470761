import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import Toast from '@components/toast';

import { ResendEmailQuery } from '@queries/userProfile.query';
import { GeneralErrorMessage } from '@constants/values';
import { getStorage, setStorage } from '@src/utils/common';
import SuccessPopup from '@components/success-popup';
import useStyles from './styles';

type VerifyEmailReminderBarProps = {
  hidden?: boolean;
  email?: string;
};

const VerifyEmailReminderBar: React.FC<VerifyEmailReminderBarProps> = ({
  hidden,
  email,
}) => {
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const hasUserClosedBanner = getStorage('hasUserClosedVerifyBanner');
  const [showBanner, setShowBanner] = useState<boolean>(
    !hidden && !hasUserClosedBanner,
  );
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  const [sendVerificationEmail] = useLazyQuery(ResendEmailQuery, {
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      if (data?.resendEmail) {
        setShowSuccessPopup(true);
      }
    },
    onError: error => {
      setErrorMessage(error.message || GeneralErrorMessage);
    },
  });

  useEffect(() => {
    setShowBanner(!hidden && !hasUserClosedBanner);
  }, [hidden]);

  if (!showBanner) {
    return null;
  }

  const onCloseBanner = () => {
    setStorage('hasUserClosedVerifyBanner', true);
    setShowBanner(false);
  };

  return (
    <>
      <div className={classes.verifyEmailReminderBarContainer}>
        We’ve already sent you an email to verify your account
        <div
          className={classes.resendVerificationText}
          role="presentation"
          onClick={() =>
            sendVerificationEmail({ variables: { input: { email } } })
          }>
          Resend Verification Email
        </div>
        <div
          className={classes.closeBanner}
          role="presentation"
          onClick={onCloseBanner}>
          <img alt="edit" src="assets/close-button.svg" />
        </div>
      </div>
      <SuccessPopup
        onClose={() => setShowSuccessPopup(false)}
        open={showSuccessPopup}
        message={`Verification link has been sent to ${email || 'your email'}`}
      />
      {errorMessage && (
        <Toast
          message={errorMessage}
          isOpen={Boolean(errorMessage)}
          handleClose={() => setErrorMessage(null)}
          toastBackground="#DA1414"
        />
      )}
    </>
  );
};

export default VerifyEmailReminderBar;
