import { makeStyles } from '@mui/styles';
import theme from '@themes/theme';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
  },
  resetPasswordRequest: {
    maxWidth: '30em',
    position: 'relative',
    height: '100%',
    margin: 'auto',
    [theme.breakpoints.down('md')]: {
      margin: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
      margin: 'auto 20px auto 20px',
    },
  },
  resetPasswordRequestWrapper: {
    position: 'absolute',
    top: 0,
    paddingTop: '30%',
  },
  title: {
    fontSize: '1.3rem',
    width: 'max-content',
  },
  signInBtn: {
    background: 'none!important',
    border: 'none',
    padding: '0!important',
    color: '#6E2CDC',
    cursor: 'pointer',
  },
  titleText: {
    textAlign: 'left',
  },
  descriptionText: {
    paddingTop: '10px',
    fontFamily: 'Manrope-Regular',
    textAlign: 'left',
    color: '#5F5F5F',
  },
  loginText: {
    color: '#6E2CDC',
    cursor: 'pointer',
  },
  emailText: {
    fontWeight: 'bold',
  },
  checkMailIcon: {
    marginTop: '50px',
    marginBottom: '20px',
    width: '60%',
    height: '60%',
  },
  checkMailDescriptionText: {
    textAlign: 'center',
  },
  checkMailTitleText: {
    textAlign: 'center',
  },
  checkMailWrapper: {
    position: 'absolute',
    top: 0,
    paddingTop: '30%',
    paddingLeft: '10%',
    paddingRight: '10%',
  },
  separator: {
    width: '100%',
    textAlign: 'center',
    borderBottom: '0.5px solid #DCDCDCCC',
    lineHeight: '0.1em',
    margin: '10px 0 20px',
  },
  passwordInput: {
    width: '100%',
    position: 'relative',
  },
  confirmPasswordInput: {
    marginTop: '20px',
    width: '100%',
    position: 'relative',
  },
  eye: {
    cursor: 'pointer',
    padding: '6px',
    paddingTop: '12px',
  },
  backButton: {
    padding: '10px',
    marginBottom: '10px',
    marginLeft: '-10px',
    display: 'flex',
    justifyContent: 'start',
  },
}));
export default useStyles;
