export const PageActions = {
  SHARE: 'Share',
  DELETE: 'Delete',
  PUBLISH: 'Publish',
  CONFIRM_PUBLISH: 'confirm_publish',
  CONFIRM_DELETE: ' confirm_delete',
  PREVIEW: 'Preview',
  EDIT: 'Edit',
  ANALYTICS: 'Analytics',
};

export const PageStatus = {
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED',
};

export const UserNameInfo = {
  NO_INFO: 'NO_INFO',
  VALID: 'VALID',
  INVALID: 'INVALID',
  FETCHING: 'FETCHING',
};

export const Mediums = {
  WHATSAPP: 'whatsapp',
  FACEBOOK: 'facebook',
  INSTAGRAM: 'instagram',
  TWITTER: 'twitter',
  MAIL: 'mail',
};

export const ModalViews = {
  share: 'SHARE',
  publish: 'PUBLISH',
  invalid_links: 'INVALID_LINKS',
};

export const LoadingTexts = {
  AUTO_SAVE: 'Autosaving...',
  PUBLISH: 'Publishing...',
};

export const ElementTypes = {
  HEADER: 'header',
  AVATAR: 'avatar',
  TITLE: 'title',
  DESCRIPTION: 'description',
  LINK: 'link',
  BLOCK: 'block',
  TEMPLATE: 'template',
};

export const PopupContentTypes = {
  READY: 'ready',
  PUBLISHED: 'published',
};

export const TemplateCardActions = [
  {
    action: PageActions.PREVIEW,
    icon: 'preview-icon-black',
    label: 'Preview',
  },
  {
    action: PageActions.PUBLISH,
    icon: 'publish-icon-black',
    label: 'Publish',
  },
  {
    action: PageActions.ANALYTICS,
    icon: 'analytics-icon-black',
    label: 'Analytics',
  },
  {
    action: PageActions.SHARE,
    icon: 'share-icon-black',
    label: 'Share',
  },
  {
    action: PageActions.DELETE,
    icon: 'delete-icon-black',
    label: 'Delete',
  },
];

export const HomePageDataLimit = 20;
export const GeneralErrorMessage = 'An error occured. please try again';
export const PageLimitErrorMessage = 'Pages count limit has reached';

export const ErrorMessagesArray = [
  'Forbidden resource',
  'jwt expired',
  'Unauthorized',
  'Unauthotized user tried to access page',
];

export const playStoreLinks = {
  whatsapp: `market://details?id=com.whatsapp`,
  instagram: `market://details?id=com.instagram`,
  facebook: `market://details?id=com.facebook.katana`,
};

export const MAX_FILE_SIZE_IN_BYTES = 1048576;
export const MAX_FILE_SIZE_IN_MB = 1;
export const templateNameMap = {
  Zocalo: 'zocalo',
  Nemrut: 'nemrut',
  'San Marco': 'sanMarco',
  Piazza: 'piazza',
  'Saint Peter': 'saintPeter',
};

export const PublicRoutes = [
  '#/login',
  '#/signup',
  '#/check-verify-email',
  '#/profile/setup',
  '#/verify-email',
  '#/check-reset-password-email',
  '#/reset-password',
  '#/resend-link',
];
