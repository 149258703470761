import { gql } from 'graphql-tag';

const ValidateUserNameQuery = gql`
  query ($userName: String!) {
    validateUserName(userName: $userName)
  }
`;

const ValidatePageUrlQuery = gql`
  query ($shortenUrl: String!) {
    validatePageUrl(shortenUrl: $shortenUrl)
  }
`;

const UserProfileQuery = gql`
  query {
    getUserProfile {
      name
      userName
      userId
      profileImage
      profileDescription
      userDetail {
        email
        phone
        isEmailVerified
        firstName
        lastName
        isBlocked
      }
    }
  }
`;

const ResendEmailQuery = gql`
  query ($input: ResendEmailInput!) {
    resendEmail(input: $input)
  }
`;

export {
  ValidateUserNameQuery,
  UserProfileQuery,
  ValidatePageUrlQuery,
  ResendEmailQuery,
};
