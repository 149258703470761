import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { PublicRoutes, ErrorMessagesArray } from '@constants/values';
import RoutesPath from '@routes/RoutesPath';
import { replaceRoute } from '@src/utils/common';

const isPublicRoute = () => {
  let publicRoute = false;
  PublicRoutes.some(item => {
    if (window.location.hash?.startsWith(item)) {
      publicRoute = true;
      return true;
    }
    return false;
  });
  return publicRoute;
};

const ErrorCheck = (message: string) => {
  if (ErrorMessagesArray.includes(message)) {
    if (!isPublicRoute()) {
      // To Do: need a refactoring in route layout
      replaceRoute(RoutesPath.login);
    }
  }
};

const link = createHttpLink({
  uri: `${process.env.REACT_APP_API_ENDPOINT}/api/graphql`,
  credentials: 'include',
});

// to manage when graphQLErrors occurs.
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) => ErrorCheck(message));
  }
  if (networkError) {
    // console.log(`[Network error]: ${networkError}`);
  }
});

const client = new ApolloClient({
  link: from([errorLink, link]),
  cache: new InMemoryCache(),
});

export default client;
