import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
  },
  contentWrapper: {
    display: 'flex',
    color: '#FFFFFF',
    fontSize: '1rem',
    padding: '16px',
    alignItems: 'center',
    marginBottom: '4.4rem',
    borderRadius: '0.3rem',
  },
  closeIcon: {
    marginLeft: '1rem',
    cursor: 'pointer',
  },
}));

export default useStyles;
