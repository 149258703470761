import React from 'react';
import { TextField, FilledTextFieldProps } from '@mui/material';
import './styles.scss';

const TextInput: React.FC<FilledTextFieldProps> = ({
  InputProps,
  InputLabelProps,
  ...props
}) => (
  <TextField
    InputProps={{
      disableUnderline: true,
      rows: InputProps?.rows ? InputProps.rows : 2,
      ...InputProps,
    }}
    variant="filled"
    fullWidth
    InputLabelProps={{
      shrink: props.value ? true : undefined,
      style: props.error ? { color: '#FF5C58' } : {},
      ...InputLabelProps,
    }}
    {...props}
    className={props.type === 'dark' ? 'darkInputField' : 'inputField'}
  />
);

export default TextInput;
