import React from 'react';
import { Snackbar, Typography } from '@mui/material';

import useStyles from './styles';

type ToastProps = {
  isOpen: boolean;
  message: string;
  handleClose: () => void;
  toastBackground: string;
};

const Toast: React.FC<ToastProps> = ({
  isOpen,
  message,
  handleClose,
  toastBackground,
}) => {
  const classes = useStyles();

  const closeHandler = () => {
    handleClose();
  };

  return (
    <div className={classes.container}>
      <Snackbar
        open={isOpen}
        onClose={closeHandler}
        autoHideDuration={6000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}>
        <div
          className={classes.contentWrapper}
          style={{ backgroundColor: `${toastBackground}` }}>
          <Typography>{message}</Typography>
        </div>
      </Snackbar>
    </div>
  );
};

export default Toast;
