import { gql } from '@apollo/client';

const GenerateOtpMutation = gql`
  mutation ($phoneNumber: String!) {
    generateOtp(input: { phone: $phoneNumber })
  }
`;

const OtpLoginMutation = gql`
  mutation ($otpInput: UserOTPLoginInput!) {
    otpLogin(input: $otpInput)
  }
`;

const LogoutMutation = gql`
  mutation {
    logout
  }
`;

const PasswordSignupMutation = gql`
  mutation ($input: UserPasswordSignupInput!) {
    passwordSignup(input: $input) {
      id
      firstName
    }
  }
`;

const PasswordLoginMutation = gql`
  mutation ($input: UserPasswordLoginInput!) {
    passwordLogin(input: $input) {
      token {
        refreshToken
        accessToken
      }
      isOnBoarded
    }
  }
`;

const SendPasswordResetLinkMutation = gql`
  mutation ($input: ResetPasswordLinkInput!) {
    sendPasswordResetLink(input: $input)
  }
`;

const ResetPasswordMutation = gql`
  mutation ($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      id
    }
  }
`;

const VerifyLinkMutation = gql`
  mutation ($input: VerifyLinkInput!) {
    verifyLink(input: $input)
  }
`;

export {
  GenerateOtpMutation,
  OtpLoginMutation,
  LogoutMutation,
  PasswordSignupMutation,
  PasswordLoginMutation,
  SendPasswordResetLinkMutation,
  ResetPasswordMutation,
  VerifyLinkMutation,
};
