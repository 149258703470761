import React from 'react';
import { Button, Box, LinearProgress, ButtonProps } from '@mui/material';

import useStyles from './styles';

interface GradientButtonProps extends ButtonProps {
  iconUrl?: string;
  text: string;
  loading?: boolean;
}

const GradientButton: React.FC<GradientButtonProps> = ({
  iconUrl,
  text,
  loading,
  ...props
}) => {
  const classes = useStyles();

  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <Button
        startIcon={<img src={iconUrl} alt="" />}
        className={classes.root}
        {...props}>
        {text}
      </Button>
      {loading && (
        <LinearProgress
          style={{ width: '97%', margin: '0 auto' }}
          color="secondary"
        />
      )}
    </Box>
  );
};

export default GradientButton;
