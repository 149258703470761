import React from 'react';
import { Modal } from '@mui/material';

import useStyles from './styles';

type SuccessPopupProps = {
  onClose: () => void;
  open: boolean;
  message?: string;
};

const SuccessPopup: React.FC<SuccessPopupProps> = ({
  open,
  onClose,
  message,
}) => {
  const classes: any = useStyles();

  return (
    <Modal className={classes.modal} open={open} onClose={onClose}>
      <div className={classes.modalContent}>
        <div className={classes.modalImageContainer}>
          <img
            className={classes.modalImage}
            src="assets/tick.svg"
            alt="Success"
          />
        </div>
        <div className={classes.successMessage}>{message || 'Success'}</div>
        <div
          className={classes.closeModal}
          role="presentation"
          onClick={onClose}>
          <img alt="edit" src="assets/close-button.svg" />
        </div>
      </div>
    </Modal>
  );
};

export default SuccessPopup;
