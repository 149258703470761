import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import client from '@services/apolloClient';
import TagManager from 'react-gtm-module';

import { GOOGLE_TAG_MANAGER_ID } from '@constants/environment';

import reportWebVitals from './reportWebVitals';
import App from './App';

const tagManagerArgs = {
  gtmId: GOOGLE_TAG_MANAGER_ID,
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('app'),
);

reportWebVitals();
