import React from 'react';
import { RecoilRoot } from 'recoil';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { HashRouter as Router } from 'react-router-dom';
import theme from '@themes/theme';
import { Routes } from './routes';
import './i18n';
import './styles/rootStyles.scss';
import './themes/mixins.scss';
import './themes/variables.scss';

const App: React.FC = () => (
  <RecoilRoot>
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <Router>
          <Routes />
        </Router>
      </StyledEngineProvider>
    </ThemeProvider>
  </RecoilRoot>
);

export default App;
