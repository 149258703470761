/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useLazyQuery, useMutation } from '@apollo/client';

import { isValidMail } from '@src/utils/validation';
import { SendPasswordResetLinkMutation } from '@mutation/login.mutation';
import { ResendEmailQuery } from '@queries/userProfile.query';
import { GeneralErrorMessage } from '@constants/values';
import Toast from '@components/toast';
import CheckEmailTemplate from '@containers/check-email-template';
import RoutesPath from '@routes/RoutesPath';
import RHFTextInput from '@components/rhf-text-input';
import GradientButton from '@components/gradient-button';
import useStyles from './styles';

type ResendLinkProps = {
  type: string;
};

const ResendLink: React.FC<ResendLinkProps> = ({ type }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);
  const pageForm = useForm({
    defaultValues: { email: '' },
  });

  const { handleSubmit, watch } = pageForm;
  const [email] = watch(['email']);

  useEffect(() => {
    const listener = event => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        handleSubmit(onSubmitEmail)();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [email]);

  const [sendPasswordResetLink] = useMutation(SendPasswordResetLinkMutation, {
    onCompleted: data => {
      if (data?.resendEmail) {
        navigate(`${RoutesPath.checkResetPasswordEmail}?email=${email}`);
      }
    },
    onError: () => {
      setErrorMessage(GeneralErrorMessage);
    },
  });

  const [sendVerificationEmail] = useLazyQuery(ResendEmailQuery, {
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      if (data?.resendEmail) {
        navigate(`${RoutesPath.checkVerifyEmail}?email=${email}`);
      }
    },
    onError: error => {
      setErrorMessage(error.message || GeneralErrorMessage);
    },
  });

  const onSubmitEmail = () => {
    if (isValidMail(email)) {
      if (type === 'resetPassword') {
        sendPasswordResetLink({ variables: { input: { email } } });
      } else {
        sendVerificationEmail({ variables: { input: { email } } });
      }
    } else {
      setErrorMessage('Invalid Email id');
    }
  };

  return (
    <CheckEmailTemplate>
      <Grid className={classes.resendLink}>
        <Grid
          container
          alignItems="center"
          className={classes.resendLinkWrapper}
          gap={4}
          textAlign="center">
          <Grid item xs={12} alignSelf="flex-end">
            <Typography
              className={classes.resendLinkTitleText}
              fontWeight="bold"
              fontSize="1.5em">
              {`Uh-Oh, ${
                type === 'resetPassword' ? 'password reset' : 'verification'
              } link expired.`}
            </Typography>
            <img
              alt="expire link"
              className={classes.resendLinkIcon}
              src="assets/expiredLink.svg"
            />
            <Typography
              className={classes.resendLinkDescriptionText}
              fontWeight="normal"
              fontSize="1em">
              This verification link has expired. Please enter your email and
              click on resend to receive a new link
            </Typography>
            <Grid item xs={12}>
              <FormProvider {...pageForm}>
                <RHFTextInput
                  childProps={{
                    label: 'Enter your registered email id',
                    variant: 'filled',
                    autoFocus: true,
                    type: 'email',
                    inputProps: {
                      style: { textTransform: 'lowercase' },
                    },
                  }}
                  name="email"
                />
                <Box style={{ marginTop: '2em', height: '3.5em' }}>
                  <GradientButton
                    disabled={!(email?.length >= 3)}
                    onClick={handleSubmit(onSubmitEmail)}
                    text="Resend link"
                  />
                </Box>
              </FormProvider>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {!!errorMessage && (
        <Toast
          message={errorMessage}
          isOpen={Boolean(errorMessage)}
          handleClose={() => setErrorMessage(null)}
          toastBackground="#DA1414"
        />
      )}
    </CheckEmailTemplate>
  );
};

export default ResendLink;
