import React, { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toUpper } from 'lodash';
import { useMutation } from '@apollo/client';
import { clearAllStorage, clearApollo } from '@src/utils/common';

import {
  AppBar,
  Toolbar,
  Box,
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  Typography,
  Divider,
} from '@mui/material';
import Logout from '@mui/icons-material/Logout';
import Settings from '@mui/icons-material/Settings';
import InsightsIcon from '@mui/icons-material/Insights';
import { RoutePath } from '@routes/index';

import Toast from '@components/toast';
import { GeneralErrorMessage } from '@constants/values';
import RoutesPath from '@routes/RoutesPath';
import formatImageUrl from '@utils/formatImageUrl';
import { LogoutMutation } from '@mutation/login.mutation';
import VerifyEmailReminderBar from '@components/verify-email-reminder-bar';

import ApplicationBarProps from './types';
import useStyles from './styles';

const ApplicationBar: React.FC<ApplicationBarProps> = ({
  profileData,
  adminPermission,
  hideProfile = false,
}) => {
  const location = useLocation();

  const [anchorEl, setAnchorEl] = useState(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [logout, { error }] = useMutation(LogoutMutation, {
    onCompleted: () => {
      clearAllStorage();
      clearApollo();
      navigate(RoutesPath.login, { replace: true });
    },
    onError: data => {
      setErrorMessage(data?.message || GeneralErrorMessage);
    },
  });
  const classes = useStyles();
  const navigate = useNavigate();
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const viewProfile = useCallback(() => {
    if (location.pathname !== RoutesPath.profile) {
      navigate(RoutesPath.profile);
    }
  }, []);

  const homeClick = useCallback(() => {
    if (location.pathname !== RoutesPath.home) {
      navigate(RoutesPath.home, { replace: true });
    }
  }, []);

  const viewAdminAnalyitics = useCallback(() => {
    navigate(RoutePath.adminAnalytics);
  }, []);

  return (
    <>
      <AppBar color="transparent" elevation={0} position="static">
        <Toolbar className={classes.appBar}>
          <Box onClick={homeClick} style={{ cursor: 'pointer' }}>
            <img
              src="assets/dazzlink.png"
              alt="logo"
              style={{ width: '10rem', height: '3rem', objectFit: 'contain' }}
            />
          </Box>
          {!hideProfile && profileData?.userName && (
            <>
              <Box className={classes.avatarBox}>
                <Box className={classes.circleBackGround}>
                  <IconButton onClick={handleClick} size="small" sx={{ ml: 0 }}>
                    <Avatar
                      src={formatImageUrl(profileData?.profileImage)}
                      sx={{ width: 40, height: 40 }}>
                      {toUpper(profileData?.userName.charAt(0))}
                    </Avatar>
                  </IconButton>
                </Box>
              </Box>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  className: classes.menuContent,
                  elevation: 0,
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
                <Box p={2} pt={1}>
                  <Typography noWrap fontWeight={700} fontSize="1.1em">
                    {profileData?.name}
                  </Typography>
                  <Typography noWrap>
                    {profileData?.userDetail?.email?.toLowerCase() ||
                      profileData?.userDetail.phone}
                  </Typography>
                </Box>
                <Divider />
                <MenuItem onClick={viewProfile}>
                  <ListItemIcon>
                    <Settings fontSize="small" />
                  </ListItemIcon>
                  Profile Settings
                </MenuItem>
                <Divider />
                {adminPermission && (
                  <MenuItem onClick={viewAdminAnalyitics}>
                    <ListItemIcon>
                      <InsightsIcon fontSize="medium" />
                    </ListItemIcon>
                    Admin Insights
                  </MenuItem>
                )}
                {adminPermission && <Divider />}
                <MenuItem onClick={() => logout()}>
                  <ListItemIcon>
                    <Logout fontSize="small" style={{ color: '#F21170' }} />
                  </ListItemIcon>
                  <Typography style={{ color: '#F21170' }}> Logout </Typography>
                </MenuItem>
              </Menu>
            </>
          )}
        </Toolbar>
      </AppBar>
      {error && (
        <Toast
          message={errorMessage}
          isOpen={Boolean(errorMessage)}
          handleClose={() => setErrorMessage(null)}
          toastBackground="#DA1414"
        />
      )}
      <VerifyEmailReminderBar
        hidden={!profileData || profileData?.userDetail?.isEmailVerified}
        email={profileData?.userDetail?.email?.toLowerCase()}
      />
    </>
  );
};

export default ApplicationBar;
