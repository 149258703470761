import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import ApplicationBar from '@components/application-bar';
import RoutesPath from '@routes/RoutesPath';

import AccountBlockedPageProps from './types';
import useStyles from './styles';

const AccountBlocked: React.FC<AccountBlockedPageProps> = ({
  profileData,
  refetchUserProfile,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    refetchUserProfile();

    if (!profileData?.userDetail?.isBlocked)
      navigate(RoutesPath.home, { replace: true });
  }, []);

  return (
    <div className={classes.accountBlockedContainer}>
      <ApplicationBar profileData={profileData} hideProfile />
      <div className={classes.accountBlockedContentWrapper}>
        <div className={classes.accountBlockedContent}>
          <div className={classes.accountBlockedTitle}>
            Your account has been blocked due to suspicious activity
          </div>
          <img
            className={classes.accountBlockedImage}
            src="/assets/account-blocked-image.svg"
            alt="account-blocked-img"
          />
          <div className={classes.accountBlockedDescription}>
            {'Please contact the admin at '}
            <span className={classes.accountBlockedMail}>hello@dazzl.ink</span>
            {' for more information'}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountBlocked;
