import { makeStyles } from '@mui/styles';
import theme from '@themes/theme';

const useStyles = makeStyles(() => ({
  accountBlockedContainer: {
    width: '100%',
    height: '100%',
  },
  accountBlockedContentWrapper: {
    height: 'calc(100% - 66px)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  accountBlockedContent: {
    width: 'fit-content',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  accountBlockedTitle: {
    textAlign: 'center',
    width: '60%',
    color: '#111111',
    fontSize: '24px',
    fontWeight: '700',
    lineHeight: '33px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '22px',
    },
  },
  accountBlockedImage: {
    width: '480px',
    [theme.breakpoints.down('md')]: {
      width: '320px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '280px',
    },
  },
  accountBlockedDescription: {
    textAlign: 'center',
    width: '60%',
    color: '#5F5F5F',
    fontSize: '20px',
    fontWeight: '500',
    lineHeight: '32px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
  },
  accountBlockedMail: {
    color: 'rgba(110, 44, 220, 1)',
    textDecoration: 'underline',
  },
}));

export default useStyles;
