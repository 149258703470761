// TODO: replace all uses of process.env with constants

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL;

const PRIMARY_DOMAIN = process.env.REACT_APP_PRIMARY_DOMAIN;

const HOTJAR_ID = process.env.REACT_APP_HOTJAR_ID;

const GOOGLE_TAG_MANAGER_ID = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID;

export {
  API_ENDPOINT,
  IMAGE_BASE_URL,
  PRIMARY_DOMAIN,
  HOTJAR_ID,
  GOOGLE_TAG_MANAGER_ID,
};
