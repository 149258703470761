import { makeStyles } from '@mui/styles';
import theme from '@themes/theme';

const useStyles = makeStyles(() => ({
  root: {
    background: theme?.colors.gradientColor,
    border: 0,
    borderRadius: 7,
    color: 'white',
    padding: '10 30px',
    textTransform: 'none',
    width: '100%',
    height: '100%',
    fontWeight: 'bold',
  },
}));
export default useStyles;
