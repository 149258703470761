/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { CircularProgress, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { ApolloQueryResult, useMutation } from '@apollo/client';

import { VerifyLinkMutation } from '@mutation/login.mutation';
import { GeneralErrorMessage } from '@constants/values';
import { UserProfile } from '@src/types/userProfile';
import CheckEmailTemplate from '@containers/check-email-template';
import RoutesPath from '@routes/RoutesPath';
import Toast from '@components/toast';
import SuccessPopup from '@components/success-popup';
import useStyles from './styles';

type HomePageProps = {
  profileData: UserProfile;
  refetchUserProfile: () => Promise<ApolloQueryResult<UserProfile>>;
};

const VerifyEmail: React.FC<HomePageProps> = ({
  profileData,
  refetchUserProfile,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const location = useLocation();
  const accessToken = location?.search?.replace('?access_token=', '');

  const [verifyLink] = useMutation(VerifyLinkMutation, {
    onCompleted: data => {
      if (data.verifyLink === 'Incorrect or expired link') {
        navigate(RoutesPath.resendEmailVerificationLink);
      } else {
        setShowSuccessPopup(true);
        setTimeout(() => {
          onCloseSuccessPopup();
        }, 3000);
      }
    },
    onError: data => {
      setErrorMessage(data?.message || GeneralErrorMessage);
    },
  });

  const onCloseSuccessPopup = () => {
    setShowSuccessPopup(false);
    navigate(RoutesPath.login);
  };

  useEffect(() => {
    refetchUserProfile();
  }, []);

  useEffect(() => {
    if (accessToken) {
      verifyLink({ variables: { input: { token: accessToken } } });
    }
  }, [accessToken]);

  if (accessToken) {
    return (
      <CheckEmailTemplate>
        {!errorMessage && !showSuccessPopup && (
          <CircularProgress style={{ color: '#FFFFFF' }} size="2em" />
        )}
        <SuccessPopup
          onClose={onCloseSuccessPopup}
          open={showSuccessPopup}
          message="Email verification successful!"
        />
        {!!errorMessage && (
          <Toast
            message={errorMessage}
            isOpen={Boolean(errorMessage)}
            handleClose={() => setErrorMessage(null)}
            toastBackground="#DA1414"
          />
        )}
      </CheckEmailTemplate>
    );
  }

  return (
    <CheckEmailTemplate>
      <div className={classes.verifyEmail}>
        <div className={classes.verifyEmailWrapper}>
          <Typography
            className={classes.verifyEmailTitleText}
            fontWeight="bold">
            Wohoo! One last step!
          </Typography>
          <Typography
            className={classes.verifyEmailDescriptionText}
            fontWeight="normal">
            We have sent you an email with the verification link to{' '}
            <span className={classes.bold}>
              {profileData?.userDetail?.email?.toLowerCase() ||
                'your registered email'}
            </span>
            . Please click on the link to verify your account
          </Typography>
          <img
            alt="edit"
            className={classes.verifyEmailIcon}
            src="assets/checkEmail.svg"
          />
          <div
            className={classes.doItLaterText}
            role="presentation"
            onClick={() => navigate(RoutesPath.home, { replace: true })}>
            Do it later
          </div>
        </div>
      </div>
    </CheckEmailTemplate>
  );
};

export default VerifyEmail;
