import { makeStyles } from '@mui/styles';
import theme from '@themes/theme';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
  },
  verifyEmail: {
    position: 'relative',
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  title: {
    fontSize: '1.3rem',
    width: 'max-content',
  },
  titleText: {
    textAlign: 'left',
  },
  descriptionText: {
    paddingTop: '10px',
    fontFamily: 'Manrope-Regular',
    textAlign: 'left',
    color: '#5F5F5F',
  },
  doItLaterText: {
    color: '#6E2CDC',
    cursor: 'pointer',
    fontFamily: 'Manrope-Regular',
    fontSize: '15px',
    paddingTop: '15px',
  },
  verifyEmailIcon: {
    marginTop: '20px',
    marginBottom: '20px',
    width: '300px',
    height: '300px',
    [theme.breakpoints.down('md')]: {
      width: '250px',
      height: '250px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '200px',
      height: '200px',
    },
  },
  verifyEmailDescriptionText: {
    textAlign: 'center',
    fontSize: '16px',
    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },
  },
  verifyEmailTitleText: {
    textAlign: 'center',
    fontSize: '22px',
    [theme.breakpoints.down('md')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
  },
  verifyEmailWrapper: {
    margin: 'auto',
    maxWidth: '500px',
    padding: '10%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  separator: {
    width: '100%',
    textAlign: 'center',
    borderBottom: '0.5px solid #DCDCDCCC',
    lineHeight: '0.1em',
    margin: '10px 0 20px',
  },
  bold: {
    fontWeight: 700,
  },
}));
export default useStyles;
