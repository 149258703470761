import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  verifyEmailReminderBarContainer: {
    width: '100%',
    minHeight: '40px',
    backgroundColor: '#EFD9FD',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '16px',
    position: 'relative',
    padding: '5px',
    [theme.breakpoints.down('md')]: {
      fontSize: '13px',
      maxHeight: '120px',
      flexDirection: 'column',
    },
  },
  resendVerificationText: {
    color: '#6E2CDC',
    fontSize: '17px',
    fontWeight: 400,
    paddingLeft: '3px',
    cursor: 'pointer',
    textDecoration: 'underline',
    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
    },
  },
  closeBanner: {
    position: 'absolute',
    right: 10,
    zIndex: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: '8px',
    paddingLeft: '8px',
    paddingTop: '5px',
    paddingBottom: '5px',
    cursor: 'pointer',
  },
}));

export default useStyles;
