import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Inter',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  breakpoints: {
    values: {
      xs: 300,
      sm: 500,
      md: 800,
      lg: 1300,
      xl: 1500,
    },
  },
  colors: {
    primaryColor: '#892CDC',
    secondaryColor: '#F21170',
    gradientColor:
      '-webkit-linear-gradient(301.26deg, #F21170 10%, #6E2CDC 55%)',
    cardShadowColor: '#7B147E59',
    textBackground: '#FFFFFF',
    appBarBackground: '#FFFFFF',
    textBoxBorderColor: '#EEEEEE',
    editableTextColor: '#9E9E9E',
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '1em',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: '#A0A0A0',
            opacity: '0.5',
          },
        },
        text: {
          textTransform: 'none',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: '0px',
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          color: '#FFFFFF',
          alignSelf: 'center',
          alignItems: 'center',
          width: '95%',
          position: 'fixed',
          left: ' 50%',
          transform: 'translate(-50%, 0)',
          borderRadius: 10,
          textTransform: 'none',
          fontWeight: 'bold',
          background:
            '-webkit-linear-gradient(301.26deg, #F21170 10%, #6E2CDC 40%)',
          zIndex: 99,
          bottom: '1.3em',
          '&.Mui-disabled': {
            color: '#A0A0A0',
            opacity: '0.5',
          },
        },
      },
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiFilledInput-root': {
            border: '1px solid #D6D6D6',
            borderRadius: 10,
            background: '#FFFFFF',
            '&.Mui-disabled': {
              background: '#F9F9F9',
            },
            '&.Mui-focused': {
              borderColor: '#6E2CDC',
              background: '#FFFFFF',
            },
            '&.Mui-error': {
              borderColor: '#FE2E00 !important',
            },
          },
          '& .MuiFormLabel-root': {
            color: '#9E9E9E',
            '&.Mui-focused': {
              color: '#9E9E9E',
            },
            '&.Mui-error': {
              color: '#9E9E9E',
            },
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          margin: 0,
          '&.Mui-expanded': {
            margin: 0,
          },
        },
      },
    },
  },
  palette: {
    text: {
      primary: '#4E4E4E',
      success: '#0BC856',
      error: '#FE2E00',
      disabled: '#A0A0A0',
    },
    border: {
      primary: 'rgba(242, 17, 112, 0.1)',
    },
    error: {
      main: '#FE2E00',
    },
    primary: {
      main: '#F21170',
    },
  },
  textClipStyles: {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordBreak: 'break-word',
    lineHeight: '19px',
  },
  commonImageStyles: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  },
  gradientOutlinedButton: {
    border: '1px solid',
    borderImageSource:
      'linear-gradient(311.26deg, #6E2CDC 41.85%, #F21170 117.52%)',
    filter: 'drop-shadow(0px 0px 5px rgba(122, 122, 122, 0.25))',
    borderRadius: 5,
  },
  scrollBarStyles: {
    height: 4,
    width: 4,
    borderRadius: 2,
    background: 'rgba(231, 231, 231, 0.2)',
    border: 'none',
  },
  scrollBarThumbStyles: {
    width: 4,
    background: '#E7E7E7',
    borderRadius: 2,
  },
  loadingTextStyles: {
    color: '#111111',
    opacity: 0.35,
  },
  backgroundImageStyles: {
    position: 'absolute',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    height: '100%',
    width: '100%',
  },
});

export default theme;
