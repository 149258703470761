export enum LoginType {
  SignIn,
  SignUp,
}

export type ProfileType = {
  name: string;
  userName: string;
  userId: string;
  profileImage: string;
  profileDescription: string;
  userDetail: {
    email: string;
    phone: string;
    isEmailVerified: boolean;
    firstName: string;
    lastName: string;
    isBlocked: boolean;
  };
};
