export default {
  default: '/',
  home: '/home',
  login: '/login',
  signup: '/signup',
  setup: '/profile/setup',
  verifyEmail: '/verify-email',
  checkVerifyEmail: '/check-verify-email',
  checkResetPasswordEmail: '/check-reset-password-email',
  profile: '/profile',
  templateSelect: '/pages-select',
  customizeTemplate: '/pages/:id/customize',
  adminAnalytics: '/pages/admin-analytics',
  resetPassword: '/reset-password',
  newPassword: '/reset-password/new-password',
  resendResetPasswordLink: '/resend-link/reset-password',
  resendEmailVerificationLink: '/resend-link/email-verification',
  accountBlocked: '/account-blocked',
};
