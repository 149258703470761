import React from 'react';
import { get } from 'lodash';
import { Controller, useFormContext } from 'react-hook-form';
import {
  FormHelperText,
  Box,
  FilledTextFieldProps,
  FormControlProps,
} from '@mui/material';

import TextInput from '@components/input-text';

interface RHFTextInputProps extends FormControlProps {
  childProps: FilledTextFieldProps;
  name: string;
}

const RHFTextInput: React.FC<RHFTextInputProps> = ({ name, childProps }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const error = get(errors, name);

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <TextInput
            error={Boolean(error)}
            {...childProps}
            {...field}
            ref={null}
          />
        )}
      />
      {error && (
        <Box columnGap="5px" display="flex" alignItems="center">
          <FormHelperText>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img width={13} height={13} src="assets/alertCircle.svg" alt="" />
              <span style={{ marginLeft: 5, color: '#FF5C58' }}>
                {error?.message}
              </span>
            </div>
          </FormHelperText>
        </Box>
      )}
    </>
  );
};

export default RHFTextInput;
