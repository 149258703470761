/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { Box, Grid, Typography, IconButton } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';

import { isValidMail, isValidPassword } from '@src/utils/validation';
import { GeneralErrorMessage } from '@constants/values';
import {
  SendPasswordResetLinkMutation,
  ResetPasswordMutation,
  VerifyLinkMutation,
  LogoutMutation,
} from '@mutation/login.mutation';
import { clearAllStorage, clearApollo } from '@src/utils/common';
import RoutesPath from '@routes/RoutesPath';
import CheckEmailTemplate from '@containers/check-email-template';
import RHFTextInput from '@components/rhf-text-input';
import GradientButton from '@components/gradient-button';
import SuccessPopup from '@components/success-popup';
import Toast from '@components/toast';
import useStyles from './styles';

const ResetPassword: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  const location = useLocation();
  const pageForm = useForm({
    defaultValues: { email: '', password: '', confirmPassword: '' },
  });
  const accessToken = location?.search?.replace('?access_token=', '');

  const { handleSubmit, watch } = pageForm;
  const [email] = watch(['email']);
  const [password] = watch(['password']);
  const [confirmPassword] = watch(['confirmPassword']);

  const [logout] = useMutation(LogoutMutation, {
    onCompleted: () => {
      clearAllStorage();
      clearApollo();
      navigate(RoutesPath.login, { replace: true });
    },
    onError: () => {
      navigate(RoutesPath.login, { replace: true });
    },
  });

  const [verifyLink] = useMutation(VerifyLinkMutation, {
    onCompleted: data => {
      if (data.verifyLink === 'Incorrect or expired link') {
        navigate(RoutesPath.resendResetPasswordLink);
      }
    },
    onError: data => {
      setErrorMessage(data?.message || GeneralErrorMessage);
    },
  });

  const [sendPasswordResetLink] = useMutation(SendPasswordResetLinkMutation, {
    onCompleted: () => {
      navigate(`${RoutesPath.checkResetPasswordEmail}?email=${email}`);
    },
    onError: data => {
      setErrorMessage(data?.message || GeneralErrorMessage);
    },
  });

  const [resetPassword] = useMutation(ResetPasswordMutation, {
    onCompleted: () => {
      setShowSuccessPopup(true);
      setTimeout(() => {
        setShowSuccessPopup(false);
        logout();
      }, 3000);
    },
    onError: () => {
      navigate(RoutesPath.login, { replace: true });
    },
  });

  const onSubmitEmail = () => {
    if (isValidMail(email)) {
      sendPasswordResetLink({ variables: { input: { email } } });
    } else {
      setErrorMessage('Invalid Email id');
    }
  };

  const onResetPassword = () => {
    if (password === confirmPassword) {
      if (isValidPassword(password)) {
        resetPassword({
          variables: {
            input: { newPassword: password, resetToken: accessToken },
          },
        });
      } else {
        setErrorMessage(
          'Password must contain minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character',
        );
      }
    } else {
      setErrorMessage('Both passwords are different');
    }
  };

  const onCloseSuccessPopup = () => {
    setShowSuccessPopup(false);
    navigate(RoutesPath.login, { replace: true });
  };

  useEffect(() => {
    if (accessToken) {
      verifyLink({ variables: { input: { token: accessToken } } });
    }
  }, [accessToken]);

  useEffect(() => {
    const listener = event => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        if (location?.pathname === '/reset-password/new-password')
          handleSubmit(onResetPassword)();
        else handleSubmit(onSubmitEmail)();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [accessToken, email, password, confirmPassword, location?.pathname]);

  useEffect(() => {
    setErrorMessage(null);
  }, [location?.pathname]);

  if (location?.pathname === '/reset-password/new-password') {
    return (
      <CheckEmailTemplate>
        <>
          <Grid className={classes.resetPasswordRequest}>
            <Grid
              container
              alignItems="center"
              className={classes.resetPasswordRequestWrapper}
              gap={4}
              textAlign="center">
              <Grid item xs={12} alignSelf="flex-end">
                <Typography
                  className={classes.titleText}
                  fontWeight="bold"
                  fontSize="1.5em">
                  Reset Password
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormProvider {...pageForm}>
                  <div className={classes.passwordInput}>
                    <RHFTextInput
                      childProps={{
                        InputProps: {
                          endAdornment: (
                            <div
                              role="presentation"
                              onClick={() =>
                                !!password && setShowPassword(!showPassword)
                              }
                              className={classes.eye}>
                              {showPassword ? (
                                <img
                                  width={18}
                                  height={18}
                                  src="assets/eye-off.svg"
                                  alt=""
                                />
                              ) : (
                                <img
                                  width={18}
                                  height={18}
                                  src="assets/eye.svg"
                                  alt=""
                                />
                              )}
                            </div>
                          ),
                        },
                        label: 'Enter your new password',
                        variant: 'filled',
                        autoFocus: true,
                        type: showPassword ? 'text' : 'password',
                      }}
                      name="password"
                    />
                  </div>
                  <div className={classes.confirmPasswordInput}>
                    <RHFTextInput
                      childProps={{
                        InputProps: {
                          endAdornment: (
                            <div
                              role="presentation"
                              onClick={() =>
                                !!confirmPassword &&
                                setShowConfirmPassword(!showConfirmPassword)
                              }
                              className={classes.eye}>
                              {showConfirmPassword ? (
                                <img
                                  width={18}
                                  height={18}
                                  src="assets/eye-off.svg"
                                  alt=""
                                />
                              ) : (
                                <img
                                  width={18}
                                  height={18}
                                  src="assets/eye.svg"
                                  alt=""
                                />
                              )}
                            </div>
                          ),
                        },
                        label: 'Confirm new password',
                        variant: 'filled',
                        type: showConfirmPassword ? 'text' : 'password',
                      }}
                      name="confirmPassword"
                    />
                  </div>
                  <Box style={{ marginTop: '2em', height: '3.5em' }}>
                    <GradientButton
                      onClick={handleSubmit(onResetPassword)}
                      text="Reset"
                    />
                  </Box>
                </FormProvider>
              </Grid>
            </Grid>
          </Grid>
          {!!errorMessage && (
            <Toast
              message={errorMessage}
              isOpen={Boolean(errorMessage)}
              handleClose={() => setErrorMessage(null)}
              toastBackground="#DA1414"
            />
          )}
          <SuccessPopup
            onClose={onCloseSuccessPopup}
            open={showSuccessPopup}
            message="Password reset successful!"
          />
        </>
      </CheckEmailTemplate>
    );
  }

  const renderResetPasswordRequestView = () => (
    <Grid className={classes.resetPasswordRequest}>
      <Grid
        container
        alignItems="center"
        className={classes.resetPasswordRequestWrapper}
        gap={4}
        textAlign="center">
        <Grid item xs={12} alignSelf="flex-end">
          <IconButton
            className={classes.backButton}
            onClick={() => navigate(-1)}>
            <img
              src="assets/arrow-left.svg"
              alt=""
              style={{ width: '22px', height: '22px' }}
            />
          </IconButton>
          <Typography
            className={classes.titleText}
            fontWeight="bold"
            fontSize="1.5em">
            Reset Password
          </Typography>
          <Typography
            className={classes.descriptionText}
            fontWeight="normal"
            fontSize="1em">
            Dont worry! We got you. Enter your registered email id to receive a
            link to reset your password.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormProvider {...pageForm}>
            <RHFTextInput
              childProps={{
                label: 'Enter your registered email id',
                variant: 'filled',
                autoFocus: true,
                type: 'email',
                inputProps: {
                  style: { textTransform: 'lowercase' },
                },
              }}
              name="email"
            />
            <Box style={{ marginTop: '2em', height: '3.5em' }}>
              <GradientButton
                disabled={!(email?.length >= 3)}
                onClick={handleSubmit(onSubmitEmail)}
                text="Submit"
              />
            </Box>
          </FormProvider>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <CheckEmailTemplate>
      <>
        {renderResetPasswordRequestView()}
        {!!errorMessage && (
          <Toast
            message={errorMessage}
            isOpen={Boolean(errorMessage)}
            handleClose={() => setErrorMessage(null)}
            toastBackground="#DA1414"
          />
        )}
      </>
    </CheckEmailTemplate>
  );
};

export default ResetPassword;
