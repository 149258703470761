import { makeStyles } from '@mui/styles';
import theme from '@themes/theme';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
  },
  verifyEmail: {
    maxWidth: '30em',
    position: 'relative',
    height: '100%',
    margin: 'auto',
    [theme.breakpoints.down('md')]: {
      margin: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
      margin: 'auto 20px auto 20px',
    },
  },
  title: {
    fontSize: '1.3rem',
    width: 'max-content',
  },
  titleText: {
    textAlign: 'left',
  },
  descriptionText: {
    paddingTop: '10px',
    fontFamily: 'Manrope-Regular',
    textAlign: 'left',
    color: '#5F5F5F',
  },
  verifyEmailIcon: {
    marginTop: '50px',
    marginBottom: '20px',
    width: '60%',
    height: '60%',
  },
  verifyEmailDescriptionText: {
    textAlign: 'center',
    fontSize: '16px',
  },
  verifyEmailTitleText: {
    textAlign: 'center',
  },
  verifyEmailWrapper: {
    position: 'absolute',
    top: 0,
    paddingTop: '30%',
    paddingLeft: '10%',
    paddingRight: '10%',
  },
  checkMailDescriptionText: {
    textAlign: 'center',
    color: '#848484',
    borderTop: 'solid',
    borderColor: '#DCDCDC',
    borderWidth: '0.5px',
    paddingTop: '10px',
    marginTop: '10px',
  },
  loginText: {
    color: '#6E2CDC',
    cursor: 'pointer',
  },
  bold: {
    fontWeight: 700,
  },
}));
export default useStyles;
