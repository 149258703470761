import { makeStyles } from '@mui/styles';
import theme from '@themes/theme';

const useStyles = makeStyles(() => ({
  appBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '66px',
    borderBottom: '1px solid rgba(231, 231, 231, 0.8)',
    padding: theme.spacing(2.5, '5%'),
    paddingRight: '70px',
    paddingLeft: '70px',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1.5, 2),
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
    boxSizing: 'border-box',
  },

  text: {
    background: theme.colors.gradientColor,
    fontSize: '1.3rem',
    fontStyle: 'normal',
    fontWeight: 'bold',
    letterSpacing: '-0.01em',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
  avatarBox: {
    width: '2.5em',
    height: '2.5em',
    padding: '.15em',
  },
  avatar: {
    width: '100%',
    height: '100%',
  },
  circleBackGround: {
    width: '100%',
    height: '100%',
    borderRadius: '5em',
    border: 'solid',
    borderColor: '#d9d9d9',
    '&:hover': {
      backgroundImage: 'linear-gradient(to left, #F21170 , #6d2bdc)',
    },
  },
  profileMenu: {
    background: theme.colors.gradientColor,
  },
  menuContent: {
    borderRadius: '1em',
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    marginTop: 8,
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      backgroundColor: '#FFFFFF',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  },
}));

export default useStyles;
