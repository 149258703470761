import { makeStyles } from '@mui/styles';
import theme from '@themes/theme';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
  },
  resendLink: {
    maxWidth: '30em',
    position: 'relative',
    height: '100%',
    margin: 'auto',
    [theme.breakpoints.down('md')]: {
      margin: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
      margin: 'auto 20px auto 20px',
    },
  },
  doItLaterText: {
    color: '#6E2CDC',
    cursor: 'pointer',
    fontFamily: 'Manrope-Regular',
    fontSize: '15px',
    paddingTop: '15px',
  },
  resendLinkIcon: {
    marginTop: '50px',
    marginBottom: '20px',
    width: '60%',
    height: '60%',
  },
  resendLinkDescriptionText: {
    textAlign: 'center',
    marginBottom: '20px',
  },
  resendLinkTitleText: {
    textAlign: 'center',
  },
  resendLinkWrapper: {
    position: 'absolute',
    top: 0,
    paddingTop: '30%',
    paddingLeft: '10%',
    paddingRight: '10%',
    paddingBottom: '10%',
  },
  separator: {
    width: '100%',
    textAlign: 'center',
    borderBottom: '0.5px solid #DCDCDCCC',
    lineHeight: '0.1em',
    margin: '10px 0 20px',
  },
}));
export default useStyles;
