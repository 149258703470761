import client from '@services/apolloClient';

const setStorage = (key: string, value: any) =>
  localStorage.setItem(key, value);

const getStorage = (key: string) => localStorage.getItem(key);

const removeStorage = (key: string) => localStorage.removeItem(key);

const clearAllStorage = () => localStorage.clear();

const clearApollo = () => client.resetStore();

const replaceRoute = newRoute => {
  window.location.hash = newRoute;
};

const copyToClipBoard = (copyText: string) => {
  if ('clipboard' in navigator) {
    navigator.clipboard.writeText(copyText);
  } else {
    const textField = document.createElement('textarea');

    textField.innerText = copyText;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
  }
};

export {
  setStorage,
  getStorage,
  removeStorage,
  clearAllStorage,
  clearApollo,
  replaceRoute,
  copyToClipBoard,
};
