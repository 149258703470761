import React from 'react';
import { Box, Hidden } from '@mui/material';

import useStyles from './styles';

const CheckEmailTemplate: React.FC = ({ children }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      {children}
      <Hidden>
        <Box className={classes.titleContainer}>
          <img
            src="assets/dazzlink.png"
            alt="logo"
            className={classes.logoContainer}
          />
        </Box>
      </Hidden>
      <Hidden mdUp>
        <Box className={classes.backgroundTop}>
          <img
            height="100%"
            width="100%"
            alt="background"
            src="assets/bg-top-mob.svg"
          />
        </Box>
      </Hidden>
      <Box className={classes.backgroundBottom}>
        <img
          height="100%"
          width="100%"
          alt="background"
          src="assets/bg-bottom.svg"
        />
      </Box>
      <Hidden mdDown>
        <Box className={classes.backgroundTop}>
          <img
            height="100%"
            width="100%"
            alt="background"
            src="assets/bg-top-mob.svg"
          />
        </Box>
      </Hidden>
    </Box>
  );
};
export default CheckEmailTemplate;
