import { makeStyles } from '@mui/styles';
import theme from '@themes/theme';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
    overflowX: 'hidden',
  },
  titleContainer: {
    padding: theme.spacing(2),
    position: 'absolute',
    top: 0,
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2, '5%'),
    },
  },
  logoContainer: {
    width: '10rem',
    height: '3rem',
    objectFit: 'contain',
    [theme.breakpoints.down('md')]: {
      width: '7rem',
      height: '1.3rem',
    },
    [theme.breakpoints.down('sm')]: {
      width: '5rem',
      height: '1.5rem',
    },
  },
  backgroundBottom: {
    zIndex: -1,
    position: 'absolute',
    overflow: 'hidden',
    left: '0',
    bottom: '0',
    height: '20em',
    width: '20em',
    [theme.breakpoints.down('xs')]: {
      height: '20em',
      width: '100vw',
    },
  },
  backgroundTop: {
    zIndex: -1,
    position: 'absolute',
    right: '-20%',
    height: '100%',
    width: '45%',
    top: '0',
    [theme.breakpoints.down('md')]: {
      right: '0',
      top: '-330px',
      height: '100%',
      width: '20em',
    },
  },
  backgroundRight: {
    position: 'absolute',
    overflow: 'hidden',
    right: '0px',
    top: '0px',
    zIndex: -1,
    height: '100vh',
  },
}));
export default useStyles;
