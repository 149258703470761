import { CircularProgress, Box } from '@mui/material';
import React, { Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import RouteLayout from './RouteLayout';
import RoutesPath from './RoutesPath';

const loader = (
  <Box display="flex" alignSelf="flex-end" mb={0.5}>
    <CircularProgress size={10} style={{ color: '#FFFFFF' }} />
  </Box>
);

const Router: React.FC = () => (
  <Suspense fallback={loader}>
    <Routes>
      <Route
        path={RoutesPath.default}
        element={<Navigate replace to={RoutesPath.home} />}
      />
    </Routes>
    <RouteLayout />
  </Suspense>
);

export default Router;
