/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import Toast from '@components/toast';
import CheckEmailTemplate from '@containers/check-email-template';
import RoutesPath from '@routes/RoutesPath';
import useStyles from './styles';

type CheckMailProps = {
  type: string;
};

const CheckEmail: React.FC<CheckMailProps> = ({ type }) => {
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const email = location?.search?.replace('?email=', '');

  return (
    <CheckEmailTemplate>
      <Grid className={classes.verifyEmail}>
        <Grid
          container
          alignItems="center"
          className={classes.verifyEmailWrapper}
          gap={4}
          textAlign="center">
          <Grid item xs={12} alignSelf="flex-end">
            <Typography
              className={classes.verifyEmailTitleText}
              fontWeight="bold"
              fontSize="1.5em">
              Check your email
            </Typography>
            <img
              alt="edit"
              className={classes.verifyEmailIcon}
              src="assets/checkEmail.svg"
            />
            <div className={classes.verifyEmailDescriptionText}>
              We have sent you an email for{' '}
              {type === 'resetPassword' ? 'reset password' : 'verification'} to{' '}
              <div className={location?.search !== email && classes.bold}>
                {location?.search === email
                  ? 'your email'
                  : email?.toLowerCase()}
              </div>
              .
            </div>
            <Typography
              className={classes.checkMailDescriptionText}
              fontWeight="normal"
              fontSize="1em">
              Back to{' '}
              <span
                className={classes.loginText}
                role="presentation"
                onClick={() => navigate(RoutesPath.login, { replace: true })}>
                Log In
              </span>
            </Typography>
          </Grid>
        </Grid>
        {errorMessage && (
          <Toast
            message={errorMessage}
            isOpen={Boolean(errorMessage)}
            handleClose={() => setErrorMessage(null)}
            toastBackground="#DA1414"
          />
        )}
      </Grid>
    </CheckEmailTemplate>
  );
};

export default CheckEmail;
