import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
  },
  modalContent: {
    maxWidth: '500px',
    backgroundColor: '#FFFFFF',
    borderRadius: '1.5em',
    margin: 'auto',
    position: 'relative',
    padding: '40px 100px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      padding: '20px 50px',
      margin: 'auto',
    },
    outline: 'none',
  },
  modalImageContainer: {
    width: '80px',
    height: '80px',
    margin: '20px',
  },
  modalImage: {
    width: '100%',
    height: '100%',
  },
  successMessage: {
    color: '#000',
    fontSize: '16px',
    fontWeight: 500,
    paddingTop: '10px',
    textAlign: 'center',
  },
  closeModal: {
    position: 'absolute',
    top: '16px',
    right: '16px',
    cursor: 'pointer',
  },
}));

export default useStyles;
